$color-sky: theme('colors.layout.shingle-gray');

:root {
	--mat-form-field-container-vertical-padding: 0;
	--mdc-outlined-text-field-hover-outline-color: #{$color-sky};
}

.csd-field {
	&--plain {
		.mat-mdc-text-field-wrapper {
			@apply p-0;
		}

		.mat-mdc-form-field-infix {
			@apply w-full p-0;
			min-height: auto;

			input::placeholder {
				@apply text-layout-quicksilver;
			}
		}
	}
}

.mat-mdc-form-field-error {
	@apply text-error-tertiary text-sm;
}
