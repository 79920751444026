:root {
	--mtx-popover-background-color: theme('colors.layout.lavender-gray');
	--mtx-popover-container-shape: theme('spacing.2');
	--mtx-popover-outline-color: theme('colors.layout.lavender-gray');
	--mtx-popover-text-color: theme('colors.layout.blue-jay');
}

.cdk-overlay-pane .mtx-popover-panel {
	@apply text-sm;
	box-shadow:
		rgba(0, 0, 0, 0.15) 3px 3px 10px 3px,
		rgba(255, 255, 255, 0.75) -3px -3px 10px 3px;
}
