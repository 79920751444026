@use './styles/base-tailwind';
@use './styles/base-mat';

@use './styles/dialog';
@use './styles/drag-and-drop';
@use './styles/expansion-panel';
@use './styles/form-field';
@use './styles/input';
@use './styles/popover';
@use './styles/progress-spinner';
@use './styles/select';
@use './styles/sidenav';
@use './styles/slide-toggle';
@use './styles/snackbar';
@use './styles/table';

$font-family: theme('fontFamily.inter');

html,
body {
	height: 100%;
	font-family: $font-family;
}

body {
	margin: 0;
}

a {
	@apply transition-all;

	&:hover {
		color: theme('colors.layout.green-brand');

		.btn-tonal {
			color: white;
		}
	}

	&.button-link {
		&:hover {
			color: inherit;
		}
	}
}

.material-symbols-filled {
	font-variation-settings: 'FILL' 1;
}
