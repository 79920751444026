:root {
	--mtx-popover-background-color: theme('colors.layout.lavender-gray');
	--mtx-popover-container-shape: theme('spacing.2');
	--mtx-popover-outline-color: theme('colors.layout.lavender-gray');
	--mtx-popover-text-color: theme('colors.layout.blue-jay');
}

.cdk-overlay-pane .mtx-popover-panel {
	@apply text-sm;
	box-shadow:
		rgba(0, 0, 0, 0.15) 0px 0px 25px 0px,
		rgba(0, 0, 0, 0.15) 0px 10px 15px 0px,
		rgba(0, 0, 0, 0.15) 0px -10px 15px 0px;
}
