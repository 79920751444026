.cdk-drop-list-dragging .cdk-drag-box:not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-handle {
	@apply cursor-pointer px-2 py-1 mr-1;
}

.cdk-drag-placeholder {
	@apply rounded;
	background: #ccc;
	border: dotted 3px #999;
	min-height: 60px;
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
