$color-text: theme('colors.layout.stone-gray');
$color-border: theme('colors.layout.quicksilver');
$table-border-radius: theme('borderRadius.DEFAULT');

:root {
	--mat-table-background-color: white;
	--mat-table-header-background-color: white;
	--mat-table-header-headline-color: #{$color-text};

	--mat-table-row-item-label-text-color: #{$color-text};
	--mat-table-row-item-outline-color: #{$color-border};
}

.mdc-data-table {
	$root: &;

	&__table {
		@apply bg-white;
	}

	&__header-row.mat-mdc-header-row {
		@apply bg-layout-sky font-bold;

		th:first-child {
			border-top-left-radius: #{$table-border-radius};
		}

		th:last-child {
			border-top-right-radius: #{$table-border-radius};
		}

		.mat-mdc-header-cell {
			@apply border-b-2 font-bold;
		}
	}

	&__row {
		@apply transition-all;

		&:hover,
		&:nth-child(even):hover {
			@apply text-layout-carbon bg-layout-lavender-gray;
		}

		&:nth-child(even) {
			@apply bg-layout-sky;
		}

		&:last-child {
			td:first-child {
				border-bottom-left-radius: #{$table-border-radius};
			}

			td:last-child {
				border-bottom-right-radius: #{$table-border-radius};
			}
		}

		td {
			border-bottom: 0;
		}
	}

	&--plain {
		#{$root}__header-row.mat-mdc-header-row {
			@apply bg-transparent;
		}

		#{$root}__row {
			@apply border-b border-layout-quicksilver;
		}

		#{$root}__row,
		#{$root}__row:hover {
			@apply text-layout-stone-gray bg-transparent;
		}
	}

	&--with-link {
		#{$root}__row {
			@apply cursor-pointer;
		}
	}

	&--with-separation {
		#{$root}__row {
			td {
				border-bottom: 5px solid transparent;
			}
		}
	}
}

.table-pagination-wrapper {
	@apply flex flex-col justify-end items-center;
	@apply xs:flex-row;

	.pagination-loader {
		@apply flex flex-col items-center justify-end font-bold italic px-3 py-2 grow;
		@apply xs:flex-row xs:justify-start;
	}

	.mat-mdc-paginator {
		@apply bg-white;
	}
}
