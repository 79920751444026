$color-lavender-gray: theme('colors.layout.lavender-gray');
$color-cadet-gray: theme('colors.layout.cadet-gray');
$color-shingle-gray: theme('colors.layout.shingle-gray');
$color-stone-gray: theme('colors.layout.stone-gray');
$color-white: theme('colors.white');

:root {
	--mdc-switch-selected-track-color: #{$color-cadet-gray};
	--mdc-switch-selected-hover-track-color: #{$color-cadet-gray};
	--mdc-switch-selected-focus-track-color: #{$color-cadet-gray};
	--mdc-switch-selected-pressed-track-color: #{$color-cadet-gray};
	--mdc-switch-selected-handle-color: #{$color-white};
	--mdc-switch-selected-focus-handle-color: #{$color-white};
	--mdc-switch-selected-hover-handle-color: #{$color-white};
	--mdc-switch-selected-pressed-handle-color: #{$color-white};
	--mdc-switch-selected-focus-state-layer-color: #{$color-white};
	--mdc-switch-selected-hover-state-layer-color: #{$color-white};

	--mdc-switch-unselected-track-color: #{$color-lavender-gray};
	--mdc-switch-unselected-hover-track-color: #{$color-lavender-gray};
	--mdc-switch-unselected-focus-track-color: #{$color-lavender-gray};
	--mdc-switch-unselected-pressed-track-color: #{$color-lavender-gray};
	--mdc-switch-unselected-handle-color: #{$color-stone-gray};
	--mdc-switch-unselected-focus-handle-color: #{$color-stone-gray};

	--mat-switch-track-outline-width: 1px;
	--mat-switch-track-outline-color: #{$color-shingle-gray};
	--mdc-switch-handle-surface-color: #{$color-white};

	.mat-slide-toggle-small {
		--mdc-switch-handle-shape: 50%;
		--mdc-switch-track-height: 22px;
		--mdc-switch-track-width: 40px;
		--mdc-switch-track-shape: 10px;
		--mat-switch-selected-handle-size: 16px;
		--mat-switch-selected-handle-horizontal-margin: 0 20px;
		--mat-switch-unselected-handle-size: 12px;
		--mat-switch-unselected-handle-horizontal-margin: 0 4px;
		--mat-switch-pressed-handle-size: 16px;
	}
}
