$color-cadet-gray: theme('colors.layout.cadet-gray');
$color-slate200: theme('colors.slate.200');

:root {
	--mat-expansion-header-focus-state-layer-color: transparent;
}

.user-area {
	$root: &;

	&__content.mat-expansion-panel-content {
		@apply absolute w-full z-10 rounded rounded-bl-none rounded-br-none;
		@apply border-solid overflow-hidden;
		background-color: $color-slate200;
		border: 2px solid $color-cadet-gray;
		border-bottom-width: 0;
		bottom: calc(100% - 10px);

		@apply opacity-100 transition-all duration-500;

		#{root} &.mat-expanded {
			opacity: 0;
		}
	}

	.mat-expansion-panel-body {
		@apply p-2 pt-6;
	}
}
