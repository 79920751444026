.mat-mdc-checkbox {
	&__space-between {
		.mat-internal-form-field {
			@apply w-full flex justify-between;
		}

		.mdc-label {
			@apply grow;
		}
	}
}
