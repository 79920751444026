$color-sky: theme('colors.layout.sky');
$color-quicksilver: theme('colors.layout.quicksilver');
$color-stone-gray: theme('colors.layout.stone-gray');
$color-slate-300: theme('colors.slate.300');

:root {
	--mat-select-panel-background-color: #fff;
	--mat-select-enabled-trigger-text-color: #{$color-stone-gray};
	--mat-select-trigger-text-size: theme('fontSize.sm');
	--mat-select-placeholder-text-color: #{$color-quicksilver};
	--mat-option-selected-state-layer-color: #{$color-sky};
	--mat-option-focus-state-layer-color: #{$color-sky};
	--mat-option-hover-state-layer-color: #{$color-slate-300};

	--mat-form-field-filled-with-label-container-padding-top: inherit;
	--mat-form-field-filled-with-label-container-padding-bottom: inherit;

	--mat-autocomplete-background-color: #fff;
}

.cdk-overlay-container .cdk-overlay-pane .mat-mdc-select-panel.mdc-menu-surface {
	@apply rounded;
}

.cdk-overlay-container
	.cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above)
	div.mat-mdc-autocomplete-panel {
	@apply rounded mt-0.5;
}

.mat-mdc-option {
	&.option-small {
		@apply text-xs px-2;
	}
}

.mat-mdc-select,
.mat-mdc-autocomplete-trigger {
	@apply border border-solid border-layout-quicksilver rounded-1;
	@apply bg-white p-2;

	&.select-text-right {
		.mat-mdc-select-value {
			@apply text-right;
		}

		.mat-mdc-select-placeholder,
		.mat-mdc-select-value-text {
			@apply pr-1.5;
		}
	}
}
