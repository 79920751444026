$font-family: theme('fontFamily.inter');
$error-primary: theme('colors.error.primary');
$error-secondary: theme('colors.error.secondary');
$info-primary: theme('colors.info.primary');
$info-secondary: theme('colors.info.secondary');
$success-primary: theme('colors.success.primary');
$success-secondary: theme('colors.success.secondary');
$warning-primary: theme('colors.warning.primary');
$warning-secondary: theme('colors.warning.secondary');

:root {
	--mdc-snackbar-supporting-text-font: #{$font-family};
	--mdc-snackbar-container-shape: 24px;

	.notification-error {
		--mdc-snackbar-container-color: #{$error-primary};
		--mdc-snackbar-supporting-text-color: #{$error-secondary};
	}

	.notification-info {
		--mdc-snackbar-container-color: #{$info-primary};
		--mdc-snackbar-supporting-text-color: #{$info-secondary};
	}

	.notification-success {
		--mdc-snackbar-container-color: #{$success-primary};
		--mdc-snackbar-supporting-text-color: #{$success-secondary};
	}

	.notification-warning {
		--mdc-snackbar-container-color: #{$warning-primary};
		--mdc-snackbar-supporting-text-color: #{$warning-secondary};
	}
}
